import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Form = ({ location }) => (
  <Layout>
    <SEO 
      pagetitle="お問い合わせ"
      pagedesc=""
      pagepath={location.pathname}
    />
    <section className="area">
      <div className="container">
      <h2 className="bar">お問い合わせ</h2>
      <p className="form-p">
        お問い合わせページをご覧いただきまして有難うございます。広告媒体に関するお問い合わせ、広告単価・お見積などお気軽にお問い合わせください。
        その他、ご相談などもご遠慮無くお問い合わせください。ご返信までに、２〜３営業日いただく場合もございますので、あらかじめご了承ください。
        お急ぎの方はお電話にて承ります。<br />TEL: <a href="tel:03-3834-8290"> 03-3834-8290 </a><br /><br />※入力した情報はSSL暗号化通信によって安全に送信されます。
        電話番号は<b>ハイフン有り</b>でご入力してください。すべて必須項目になっております。<br />
        個人情報の取り扱いにつきましては下記プライバシーポリシーよりお読みください。
      </p>
      <details>
      <summary>プライバシーポリシー</summary>
      <p className="privacy"><br />
      <h3>個人情報取り扱いについて</h3>
      有限会社広文社（以下、これらを総称して当社といいます）では、個人情報保護方針を以下のとおりと定めます。
      また、個人情報管理につきましても、個人情報に関する法令及び諸規範を遵守し、お客様の信頼にお答えできるサービスを提供してまいります。<br /><br />
      <h4>個人情報保護方針</h4>
      <h4>1) 適切な個人情報の収集と利用</h4>
      当社は適法な手段により、収集目的を明確にした上で、
      目的の範囲内に限り、本人同意の上、個人情報を収集します。
      また、氏名、住所、会社名、メールアドレスなど個人を特定できる個人情報の利用は
      本人の同意を得た収集目的から逸脱しない範囲とします。<br /><br />
      <h4>2) 個人情報の適正な管理と保護</h4>
      個人情報の管理は、厳重に行うこととし、お客さまにご承諾いただいた場合を除き
      第三者に対しデータを開示・提供することはいたしません。
      また個人情報に関する不正アクセス、紛失、破壊、改ざん
      漏洩を防ぐための適切な予防および是正処置を行います。<br /><br />
      <h4>3) 法令、社内規定等の遵守</h4>
      当社は、当社が保有する個人情報に関して適用される法令およびその他の規範を遵守いたします。<br /><br />
      <h4>4) 個人情報保護管理体制および仕組みの継続的改善</h4>
      当社では、個人情報保護に関する管理の体制と仕組みについて継続的改善を実施いたします。<br /><br />
      <h3>個人取り扱いについて</h3>
      当社では、個人情報に関しては、管理責任者を配置し、適切かつ安全な管理を行っています。
      当社はお客さまの個人情報を適切な方法で管理いたします。
      特別の事情がない限りお客さまの承認なく,第三者に開示・貸与、譲渡はいたしません。
      ただし,法的機関等により申し出があった場合や当社の権利・財産を保護する目的などにおいては
      この限りではありません。<br />
      お客さま個人に関する情報（以下「個人情報」）を収集する場合は
      以下の目的のために利用することがあります。<br />
      ・お問い合わせへのご回答<br />
      ・お問い合わせされた情報のお知らせ<br />
      あらかじめその目的・利用内容をお知らせし、お客さまの意志で同意・登録をいただいております。
      利用目的を逸脱して当該情報を利用することはいたしません。
      今後、当社はプライバシーの考え方の全部または一部を改訂することがあります。
      重要な変更がある場合には、当ホームページ上において、分かりやすい方法でお知らせいたします。<br /><br />
      </p>
      </details>
      <p class="form">
      <form 
      　name="contact" 
      　method="POST" 
      　data-netlify="true"
      　data-netlify-honeypot="bot-field"
    　>
    　<input type="hidden" name="form-name" value="contact" />
    　<input type="hidden" name="bot-field" />

      <div className="form-area">
        <input type="text" className="form-control" id="name" name="name" placeholder="お名前" maxlength="30" minlength="2" required autocomplete="name" />
      </div>
      <div className="form-area">
        <input type="text" className="form-control" id="name" name="company" placeholder="会社名" maxlength="30" minlength="2" required autocomplete="off" />
      </div>
      <div className="form-area">
        <input type="text" className="form-control" id="name" name="ad" placeholder="広告媒体 or 件名" maxlength="30" minlength="2" required autocomplete="off" />
      </div>
      <div className="form-area">
        <input type="text" className="form-control" id="name" name="tel" placeholder="電話 ハイフン(-)必須" pattern="\d{2,4}-\d{2,4}-\d{3,4}" maxlength="13" minlength="10" required autocomplete="name" />
      </div>
      <div className="form-area">
        <input type="email" className="form-control" id="email" name="email" placeholder="メールアドレス" pattern="^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$" required autocomplete="email" />
      </div>
      <div className="form-area">
        <textarea className="form-control" id="contact" name="content" rows="7" placeholder="メッセージ" required></textarea>
      </div>
      <div className="form-area">
      <button type="submit">送 信</button>
      </div>
  　　</form>
    </p>
      </div>
    </section>
  </Layout>
)

export default Form